import { useTranslation } from "react-i18next";

function TranslateLinks() {
  const { i18n } = useTranslation("translation");

  return (
    <div className="margin-bottom-4">
      <a href="#">
        <img
          onClick={() => i18n.changeLanguage("fr")}
          src="https://res.cloudinary.com/payqin-ltd/image/upload/v1626280731/main%20website/Screenshot_316_ljuop5_lph570.png"
          alt="flag-fr"
          height="16"
        />
      </a>
      <a href="#" className="margin-left-8">
        <img
          onClick={() => i18n.changeLanguage("en")}
          src="https://res.cloudinary.com/payqin-ltd/image/upload/v1626280725/main%20website/Screenshot_317_gigu9b_otg3wr.png"
          alt="flag-en"
          height="16"
        />
      </a>
    </div>
  );
}

export default TranslateLinks;
