import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { HashRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import ScrollToTop from "./components/Scroll";

document.title = "PayQin - Paiement simple pour les pays émergents";

const resources = {
  en: {
    translation: common_en,
  },
  fr: {
    translation: common_fr,
  },
};
// Translations
i18n.init({
  resources,
  lng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
