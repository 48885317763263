import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TranslateLinks from "./TranslateLinks";
import "../styles/Header.css";

function Header() {
  const { t } = useTranslation("translation");

  return (
    <header className="navbar navbar-expand-md navbar-light fixed-top background-light padding-width-30 header">
      <a href="https://payqin.com/">
        <img
          src="https://res.cloudinary.com/payqin-ltd/image/upload/v1626280733/main%20website/n3-1_vscqvt_ljdred.png"
          className="header-image"
          alt="PayQin Logo"
        />
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-content"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse mx-3" id="navbar-content">
        <nav className="margin-left-auto">
          <ul className="navbar-nav header-box">
            <li className="nav-item header-item">
              <NavLink to="/overview" className="nav-link">
                {t("header.overview")}
              </NavLink>
            </li>
            <li className="nav-item header-item">
              <NavLink to="/privacy-policy" className="nav-link">
                {t("header.privacy")}
              </NavLink>
            </li>
            <li className="nav-item header-item">
              <NavLink to="/terms" id="terms" className="nav-link">
                {t("header.terms")}
              </NavLink>
            </li>
            <li className="nav-item header-item">
              <a className="nav-link" href="https://payqin.com/">
                {t("header.visit-payqin")}
              </a>
            </li>
            <li className="nav-item header-item translate">
              <TranslateLinks />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
