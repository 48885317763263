import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { displayError, handleAPIError } from "../../helpers/error";
import getPolicy from "../../api/policy/getPolicy";
import termsEn from "../../assets/terms/terms-and-conditions-payqin.pdf";
import termsPicture from "../../assets/terms/terms.jpg";

function TermsEn() {
  const { t } = useTranslation("translation");
  const [isLoading, setLoading] = useState(true);
  const [terms, setTerms] = useState(" ");
  const [update, setUpdate] = useState(" ");

  const loadPolicy = async () => {
    try {
      const policy = await getPolicy({ policyTitle: "terms-en" });
      handleAPIError(policy);
      setTerms(policy.text);
      setUpdate(policy.update.substring(0, policy.update.indexOf("T")));
    } catch (error) {
      console.error(error);
      displayError(error);
    }
  };

  const onLoad = async () => {
    setLoading(true);
    await loadPolicy();
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className="legal-box">
      {isLoading && (
        <>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}

      {!isLoading && (
        <>
          <div className="legal-policy-image">
            <img src={termsPicture} className="legal-image" alt="Terms" />
          </div>
          <div className="container legal-text">
            <h4 className="margin-height-20">TERMS & CONDITIONS</h4>
            <div className="legal-download">
              <p>Updated at {update}</p>
              <span className="legal-span">|</span>
              <span>
                <a href={termsEn} download="Terms-&-Conditions">
                  {t("terms.download")}
                </a>
              </span>
            </div>

            {ReactHtmlParser(terms)}
          </div>
        </>
      )}
    </div>
  );
}

export default TermsEn;
