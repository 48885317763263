import React from "react"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import { displayError, handleAPIError } from "../../helpers/error"
import getPolicy from "../../api/policy/getPolicy"
import privacyEn from "../../assets/privacy/privacy-policy-payqin.pdf"
import privacyPicture from "../../assets/privacy/privacy.jpg"

function PrivacyEn() {
  const { t, } = useTranslation("translation")
  const [isLoading, setLoading] = useState(true)
  const [privacy, setPrivacy] = useState(" ")
  const [update, setUpdate] = useState(" ")

  const loadPolicy = async () => {
    try {
      const policy = await getPolicy({ policyTitle: "privacy-en" })
      handleAPIError(policy)
      setPrivacy(policy.text)
      setUpdate(policy.update.substring(0, policy.update.indexOf("T")))
    } catch (error) {
      console.error(error)
      displayError(error)
    } 
  }

  const onLoad = async () =>{
    setLoading(true)
    await loadPolicy()
    setLoading(false)
  }

  useEffect(()=>{
    onLoad()
  }, [])

  return (
    <div className="legal-box">
      {isLoading && (
        <>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
      
      {!isLoading && (
        <>
          <div className="legal-policy-image">
            <img 
              src={ privacyPicture } 
              className="legal-image" 
              alt="Privacy Policy" 
            />
          </div>
          <div className="container legal-text">
            <h4 className="margin-height-20">PRIVACY POLICY</h4>
            <div className="legal-download">
              <p>Updated at { update }</p>
              <span className="legal-span">|</span>
              <span>
    s            <a 
                  href={ privacyEn } 
                  download="Privacy-Policy" 
                >
                  { t("privacy.download") }
                </a>
              </span>
            </div>

            { ReactHtmlParser(privacy) }
          </div>
        </>
      )}
    </div>
  )
}

export default PrivacyEn
