import { toast } from "react-toastify";

// throw an exception when an api error occurs
export function handleAPIError(payload) {
  const { success, error, errorMessage, message, statusText } = payload;
  if (success === false || error === true) {
    const displayMessage = errorMessage || statusText || message;
    throw displayMessage;
  }
}

export function displayError(error) {
  var _displayError =
    typeof error === "string"
      ? error
      : "message" in error
      ? error.message
      : error.toString();
  toast.error(_displayError);
}
