import host from "../host";
import { getToken } from "../../helpers/token";
import I18n from "i18n-js";

export default async function getPolicy({ policyTitle }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      "Accept-Language": I18n.currentLocale(),
      mode: "no-cors",
      version: 330,
    },
  };

  const response = await fetch(
    `${host}/admin/policy?title=${policyTitle}`,
    requestOptions
  );
  return response.json();
}
