import { useTranslation } from "react-i18next";
import "../styles/Footer.css";
import PciLogo from "../assets/pci-logo.png";

function Footer() {
  const { t } = useTranslation("translation");

  return (
    <footer className="footer background-light padding-height-20 bottom-fixed">
      <div className="footer-contain">
        <div className="footer-logo">
          <a href="https://payqin.com/">
            <img
              src="https://res.cloudinary.com/payqin-ltd/image/upload/v1626280733/main%20website/n3-1_vscqvt_ljdred.png"
              className="footer-image"
              alt="PayQin Logo"
            />
          </a>
        </div>

        <div className="footer-links">
          <a href="https://payqin.com/about/">{t("footer.about")}</a>
          <a href="https://payqin.crisp.help/fr/">{t("footer.help")}</a>
          <a href="https://blog.payqin.com/">Blog</a>
          <a href="https://ambassadeur.payqin.com/">{t("footer.ambassador")}</a>
        </div>

        <div className="footer-socials">
          <a href="https://www.facebook.com/payqin/">
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="https://twitter.com/payqinn?lang=fr/">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/payqin/">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://fr.pcisecuritystandards.org/minisite/env2/">
            <img src={PciLogo} className="pci-logo" />
          </a>
        </div>
      </div>

      <div className="footer-reference">PayQin © 2023 Tous droits reservés</div>
    </footer>
  );
}

export default Footer;
