import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "../styles/Home.css";

function Home() {
  const { t } = useTranslation("translation");

  return (
    <div className="container">
      <div className="home-box">
        <div className="home-legal margin-right-30">
          <h1 className="home-title margin-bottom-15">
            {t("home.privacy-title")}
          </h1>
          <p>{t("home.privacy-text")}</p>
          <NavLink to="/privacy-policy" className="home-link">
            {t("home.privacy-link")}
          </NavLink>
        </div>

        <div className="home-legal">
          <h1 className="home-title margin-bottom-15">
            {t("home.terms-title")}
          </h1>
          <p>{t("home.terms-text")}</p>
          <NavLink to="/terms" className="home-link">
            {t("home.terms-link")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Home;
