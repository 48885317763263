import { useTranslation } from "react-i18next";
import TermsEn from "../utils/Terms/terms-en";
import TermsFr from "../utils/Terms/terms-fr";
import "../styles/utils.css";

function Terms() {
  const { t } = useTranslation("translation");

  return t("terms.langage") === "English" ? <TermsEn /> : <TermsFr />;
}

export default Terms;
