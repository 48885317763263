import { useTranslation } from "react-i18next";
import PrivacyEn from "../utils/Privacy/privacy-en";
import PrivacyFr from "../utils/Privacy/privacy-fr";
import "../styles/utils.css";

function Privacy() {
  const { t } = useTranslation("translation");

  return t("privacy.langage") === "English" ? <PrivacyEn /> : <PrivacyFr />;
}

export default Privacy;
